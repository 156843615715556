import DefaultPage from "../components/defaultPage";
import PageHeader from "../components/pageHeader";
import TextBlock from "../components/textBlock";
import { Helmet } from "react-helmet";

const DeliveryPage = () => {
	return (
		<>
			<Helmet>
				<title>Доставка и оплата</title>
			</Helmet>
			<PageHeader
				title="Доставка"
				breadcrumbs={[
					{
						link: "/",
						text: "Доставка",
					},
				]}
			/>
			<DefaultPage>
				<TextBlock>
					<>
						{/* <p>Доставка товаров, представленных в интернет-магазине нашей компании, осуществляется собственной службой доставки по Алматы и курьерской службой или почтой по всему Казахстану.</p>
                        <h3>Бесплатно, если сумма заказа превышает 50 000 тенге.</h3>
                        <p>Если сумма заказа менее 50 000 тенге, то стоимость доставки рассчитывается индивидуально, в зависимости от удаленности пункта назначения.</p>
                        <h3>Сроки доставки</h3>
                        <ol>
                            <li>Сроки доставки по Алматы могут составить от нескольких часов до 1-2 рабочих дней, после подтверждения заказа менеджером.</li>
                            <li>Сроки доставки по Казахстану могут составить от 2 до 4 рабочих дней, после подтверждения заказа менеджером. Сроки зависят от места назначения.</li>
                        </ol> */}

						<h3>Условия доставки</h3>
						<ol>
							<li>
								Бесплатная доставка по Казахстану: осуществляется при заказе на
								сумму свыше 70 000 тг.
							</li>
							<li>
								Если сумма заказа менее 70 000 тг: стоимость доставки
								рассчитывается индивидуально, исходя из удаленности пункта
								назначения.
							</li>
						</ol>

						<h3>Сроки доставки</h3>
						<h5>По городу Алматы</h5>
						<ol>
							<li>
								После подтверждения заказа менеджером доставка занимает 1–2
								рабочих дня.
							</li>
						</ol>
						<h5> Экспресс-доставка по Алматы в течение суток</h5>
						<ol>
							<li>
								Для заказов на сумму менее 150 000 тг стоимость составляет 3 000
								тг.
							</li>
							<li>
								При заказе свыше 150 000 тг экспресс-доставка осуществляется
								бесплатно.
							</li>
						</ol>
						<h4>По территории Казахстана</h4>
						<ol>
							<li>
								После подтверждения заказа менеджером доставка занимает от 2 до
								5 рабочих дней, в зависимости от места назначения
							</li>
						</ol>

						<h3>Способы оплаты</h3>
						<h5>В Алматы</h5>

						<ol>
							<li>Оплата возможна при самовывозе или при доставке товара.</li>
							<li>Безналичный расчет</li>
						</ol>

						<h5>По Казахстану</h5>

						<ol>
							<li>Оплата на сайте с помощью карты.</li>
							<li>
								Также возможно выставление удаленного счета для оплаты через
								Kaspi Pay.
							</li>
						</ol>

						<p>
							Если у вас остались вопросы, наши менеджеры всегда готовы помочь!
						</p>
					</>
				</TextBlock>
			</DefaultPage>
		</>
	);
};

export default DeliveryPage;
